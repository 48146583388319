import React from "react";
import Background from '../assets/images/WelcomeBgImage.png'

const FinalPage = () => {

    const handleClick = () => {
      const button = document.getElementById('actionButton')
        if (button) {
            button.click()
        }
    }
    return (
        <div style={{
            backgroundImage: `url(${Background})`,
            width: '100vw',
            height: '100vh',
            margin: '-30px -10px',
            backgroundSize: '100% 100%',
            backgroundColor: 'currentcolor',
        }}>
            <div className={'mainIconDiv'}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent:'center'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35" fill="none">
                        <path d="M0 0H34.0447V34.0447H0V0Z" fill="none" fill-opacity="0.01"/>
                        <path d="M34.0447 0H0V34.0447H34.0447V0Z" fill="none" fill-opacity="0.01"/>
                        <path
                            d="M20.9349 12.4123C24.8521 19.197 24.4525 26.7612 20.0424 29.3073C15.6324 31.8535 8.8818 28.4174 4.96465 21.6327C1.04749 14.848 1.44708 7.28384 5.85714 4.73771C10.2672 2.19156 17.0178 5.6276 20.9349 12.4123Z"
                            stroke="#fff" stroke-width="2.83706" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M12.95 12.4123C9.03287 19.197 9.4324 26.7612 13.8425 29.3073C18.2525 31.8535 25.0031 28.4174 28.9203 21.6327C32.8374 14.848 32.4378 7.28384 28.0277 4.73771C23.6177 2.19156 16.8671 5.6276 12.95 12.4123Z"
                            stroke="#fff" stroke-width="2.83706" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M16.847 7.33691C18.3684 8.68998 19.7757 10.4045 20.9349 12.4123C24.8521 19.1969 24.4525 26.7611 20.0424 29.3073C19.1217 29.8389 18.099 30.1097 17.0213 30.1437"
                            stroke="#fff" stroke-width="2.83706" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <h1 className={'finalHeader'}>
                        Gimme
                    </h1>
                </div>
                <h2 className={'finalText'}>Gimme is a mobile extension which allows you to shop with your friends.</h2>
            </div>
            <div className={'buttonDiv'} id={'crewAppButton'} onClick={handleClick}>
                <p className={'linkToApp'}>Explore the extension</p>
            </div>
        </div>
    )
};

export default FinalPage;
