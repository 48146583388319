import './App.css';
import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/FirstPage";
import SecondStep from "./pages/SecondPage";
import FinalPage from "./pages/FinalPage";

function App() {
    return (
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="/secondStep" element={<SecondStep/>}/>
            <Route exact path='/secondStep/finalPage' element={<FinalPage/>}/>
        </Routes>
    );
}

export default App;
