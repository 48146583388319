import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const Home = () => {
    const element = document.getElementById('actionButton')
    const navigate = useNavigate()
    useEffect(()=>{
        if (element?.innerHTML) {
            navigate('/secondStep/finalPage')
        }

    }, [element])
    return (
        <div>
            <div className={'mainIconDiv'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 35 35" fill="none">
                    <path d="M0 0H34.0447V34.0447H0V0Z" fill="white" fill-opacity="0.01"/>
                    <path d="M34.0447 0H0V34.0447H34.0447V0Z" fill="white" fill-opacity="0.01"/>
                    <path d="M20.9349 12.4123C24.8521 19.197 24.4525 26.7612 20.0424 29.3073C15.6324 31.8535 8.8818 28.4174 4.96465 21.6327C1.04749 14.848 1.44708 7.28384 5.85714 4.73771C10.2672 2.19156 17.0178 5.6276 20.9349 12.4123Z" stroke="#0E0BB6" stroke-width="2.83706" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.95 12.4123C9.03287 19.197 9.4324 26.7612 13.8425 29.3073C18.2525 31.8535 25.0031 28.4174 28.9203 21.6327C32.8374 14.848 32.4378 7.28384 28.0277 4.73771C23.6177 2.19156 16.8671 5.6276 12.95 12.4123Z" stroke="#0E0BB6" stroke-width="2.83706" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.847 7.33691C18.3684 8.68998 19.7757 10.4045 20.9349 12.4123C24.8521 19.1969 24.4525 26.7611 20.0424 29.3073C19.1217 29.8389 18.099 30.1097 17.0213 30.1437" stroke="#0E0BB6" stroke-width="2.83706" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <h1>Enable Gimme on Safari.</h1>
            <p>
                We newer sell, store or view your data. Check out our <a href={'google.com'}>privacy policy</a> to learn
                more.
            </p>
            <div className={'greyContainer'}>
                <p className={'text'}>1. Tap the <b>aA</b> in the address bar</p>
                <img src={require('../assets/images/AaImage.png')} alt={'aA'} className={'imagePng'}/>
            </div>
            <div className={'greyContainer'}>
                <p className={'text'}>2. Then go to <b>Manage Extension</b></p>
                <img src={require('../assets/images/Puzzle.png')} alt={'extensionIcon'} className={'imagePng'}/>
            </div>
            <div className={'greyContainer'}>
                <p className={'text'}>3. Toggle on <b>Gimme Extension"</b> and select <b>"Done"</b></p>
                <img src={require('../assets/images/SwitchButton.png')} alt={'switchButton'} className={'imagePng'}/>
            </div>
        </div>
    )
};

export default Home;
